
import analytics from '~/mixins.js/analytics'

export default {
  components: {
    'before-after-carousel': () => import('./BeforeAfterCarousel.vue')
  },
  mixins: [analytics],
  data () {
    return {
      beforeAfterImages: []
    }
  },
  fetchOnServer: false,
  async fetch () {
    this.beforeAfterImages = await this.$backend.before_after.list()
  }
}
